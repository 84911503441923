




import { Vue, Component, Prop } from "vue-property-decorator";
import { default as ChartJs, ChartData, ChartOptions, ChartType } from "chart.js/auto";
import { PropType } from "vue";

@Component
export default class Chart extends Vue {
  public name = "Chart";

  @Prop({ type: String, default: () => "line-chart" })
  public id!: string;

  @Prop({ type: Object as PropType<ChartData> })
  public data!: ChartData;

  @Prop({ type: String as PropType<ChartType>, default: () => "line" })
  public type!: ChartType;

  @Prop({ type: Object as PropType<ChartOptions> })
  public options!: ChartOptions;

  public $chart: null | ChartJs = null;

  public renderChart() {
    this.$chart = new ChartJs(this.$refs.chart as HTMLCanvasElement, {
      type: this.type,
      data: this.data,
      options: this.options,
    });
  }

  public destroyChart() {
    if (this.$chart) {
      this.$chart.destroy();
    }
  }

  public mounted(): void {
    this.renderChart();

    this.$watch(() => this.data, this.chartUpdateHandler, { deep: true });
  }

  public beforeDestroy() {
    this.destroyChart();
  }

  public chartUpdateHandler(newData: any, oldData: any) {
    if (oldData) {
      let chart = this.$chart;

      // Get new and old DataSet Labels
      let newDatasetLabels = newData.datasets.map((dataset: any) => {
        return dataset.label;
      });

      let oldDatasetLabels = oldData.datasets.map((dataset: any) => {
        return dataset.label;
      });

      // Stringify 'em for easier compare
      const oldLabels = JSON.stringify(oldDatasetLabels);
      const newLabels = JSON.stringify(newDatasetLabels);

      // Check if Labels are equal and if dataset length is equal
      if (newLabels === oldLabels && oldData.datasets.length === newData.datasets.length) {
        newData.datasets.forEach((dataset: any, i: any) => {
          // Get new and old dataset keys
          const oldDatasetKeys = Object.keys(oldData.datasets[i]);
          const newDatasetKeys = Object.keys(dataset);

          // Get keys that aren't present in the new data
          const deletionKeys = oldDatasetKeys.filter((key) => {
            return key !== "_meta" && newDatasetKeys.indexOf(key) === -1;
          });

          // Remove outdated key-value pairs
          deletionKeys.forEach((deletionKey) => {
            //@ts-ignore
            delete chart.data.datasets[i][deletionKey];
          });

          // Update attributes individually to avoid re-rendering the entire chart
          for (const attribute in dataset) {
            if (Object.prototype.hasOwnProperty.call(dataset, attribute)) {
              //@ts-ignore
              chart.data.datasets[i][attribute] = dataset[attribute];
            }
          }
        });

        if (Object.prototype.hasOwnProperty.call(newData, "labels")) {
          //@ts-ignore
          chart.data.labels = newData.labels;
        }
        if (Object.prototype.hasOwnProperty.call(newData, "xLabels")) {
          //@ts-ignore
          chart.data.xLabels = newData.xLabels;
        }
        if (Object.prototype.hasOwnProperty.call(newData, "yLabels")) {
          //@ts-ignore
          chart.data.yLabels = newData.yLabels;
        }
        //@ts-ignore
        chart.update();
      } else {
        if (chart) {
          chart.destroy();
        }
        this.renderChart();
      }
    } else {
      this.destroyChart();
      this.renderChart();
    }
  }
}
